import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>What we offer?</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items animate__animated animate__fadeInRight animate__delay-2s'>
            <CardItem
              src='images/root-canal.jpg'
              text='Root Canal treatments are advised when the bacteria development reaches more in the root area in the pulp of the tooth.'
              label='Root Canal Treatments'
              path='/services'
            />
            <CardItem
              src='images/dental-implants.jpg'
              text='These are used in the case of missing teeth. They replace the roots of the missing teeth which can support the bridges, crowns, or dentures.'
              label='Dental Implants'
              path='/services'
            />
            <CardItem
              src='images/tooth-extraction.jpg'
              text='Tooth extraction is done only in the situation where it is not possible to protect the damaged tooth from any treatment.'
              label='Tooth Extraction'
              path='/services'
            />
          </ul>
          <ul className='cards__items animate__animated animate__fadeInLeft animate__delay-2s'>
            <CardItem
              src='images/dental-braces.jpg'
              text='Some of the main types of braces are Metallic Braces, Cosmetic Braces, Ceramic Braces, Lingual Braces, etc.'
              label='Dental Braces Treatment'
              path='/services'
            />
            <CardItem
              src='images/cosmetic-dentistry.jpg'
              text='Smile makeover treatment can help to solve Stains on teeth, chips on the tooth, and several other issues that make a person’s smile inappropriate.'
              label='Cosmetic Dentistry'
              path='/products'
            />
            <CardItem
              src='images/orthodontics.jpg'
              text='Some of the main types of braces are Metallic Braces, Cosmetic Braces, Ceramic Braces, Lingual Braces, etc.'
              label='Orthodontics'
              path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
