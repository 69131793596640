import React from 'react';
import '../../App.css';

export default function WhyUs() {
  return <div class='aboutus'>
    <div class="row">
        <div class="col-xs-12 col-md-12"><h1>Why Us?</h1></div>
    </div>
  <div class="row">
  <div class="col-xs-9 col-md-7 animate__animated animate__fadeInLeft animate__delay-2s">
      <p class="para" />Our state of the art dental clinic has a unique combination of a relaxing ambience along with use of cutting edge equipment and internationally acclaimed dental materials. The atmosphere is unlike regular expected dental clinics. We follow excellent protocol for infection control and hygiene and are associated with the best laboratories in the country. We are extremely proud of our pleasant, friendly and well trained staff going out of the way to make patients feel at home and deliver personalized dental care ranging from simple prophylaxis to a complete smile make-over!!

<p />Well equipped with cutting edge armamentarium:<br />
<i class='fas fa-check-circle col-green' />Automated dental chairs imported and assembled in Germany -for optimum comfort and efficiency<br />
<i class='fas fa-check-circle col-green' />RVG: digital dental x ray software- less time consuming and reduced exposure to radiations<br />
<i class='fas fa-check-circle col-green' />Autoclave for sterilization with the latest technology in an isolated area<br />
<i class='fas fa-check-circle col-green' />Disposable products to maintain high levels of patient hygiene<br />
<i class='fas fa-check-circle col-green' />Top of the line instruments and dental materials - only best in the world.
  </div>
  <div class="col-xs-3 col-md-5 animate__animated animate__fadeInRight animate__delay-3s"><img src='./images/06.jpg' alt='banner' width='100%' class='left-banner' /></div>
</div>
</div>;
}
