import React from 'react';
import '../../App.css';

export default function AboutUs() {
  return <div class='aboutus'>
    <div class="row">
        <div class="col-xs-12 col-md-12"><h1>About Us</h1></div>
    </div>
  <div class="row">
  <div class="col-xs-9 col-md-7 animate__animated animate__fadeInLeft animate__delay-2s">
      <p class="para" />Beyond Smiles Dental Clinic is one of the Dental Clinics in Jalandhar. We are a team of dental surgeons holding expertise in cosmetic dental treatments, preventative procedures and all types of dental restorations. We have designed the practice in such a way that helps us to treat our patients in a calm & comfortable environment that results in beautiful & confident smiles. Our clinic is equipped with all the latest & modern dental equipment that helps us to give you the best dental care in the least possible time.

    <br /><br />We understand that just offering the best dental treatments is not enough, we make sure that we provide you a safe environment and follow all the COVID safety measures to protect you. On your first visit, our friendly and caring staff members will explain to you about all the safety protocols so that you have a wonderful experience every time you visit us.We make sure that you don’t have to wait long for your treatment as we follow a strict appointment system.
  </div>
  <div class="col-xs-3 col-md-5 animate__animated animate__fadeInRight animate__delay-3s"><img src='./images/about-us.jpeg' alt='banner' width='100%' class='left-banner' /></div>
</div>
</div>;
}
