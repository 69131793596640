import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/dentist.mp4' autoPlay loop muted />
      <section class='social-media'>
        
          <div class='into'>
            <h1>Dr. Aggarwal</h1>
            <p>Care for your smile</p>
            <p class="phone"><i class='fas fa-phone-square-alt' /> +91 78752 07987</p>
            <p class="address"> 324, Urban Estate-II 144001
Phase 2, Urban Estate,
Jalandhar
Punjab-144011</p>
          </div>
          
          
        
      </section>
      
      
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Have Questions?
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Contact <i className='far fa-phone' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
